<script setup lang="ts">
import { useBranchStore } from '~/store/branches'
import { useTariff } from '~/store/tariff'

const router = useRouter()

const store = useBranchStore()
const tariff = useTariff()
const { fetchBranch } = store

await fetchBranch(router.currentRoute.value.params.id)
</script>

<template>
  <div class="flex flex-col min-h-full">
    <layout-header class="w-full" />
    <freemium-form />
    <freemium-info />

    <div class="flex-1 flex min-h-full max-w-[1500px] overflow-x-auto">
      <layout-aside :class="tariff.checkFeature('freemium') ? 'pt-24' : 'pt-16'" />
      <div class="flex-auto px-14" :class="tariff.checkFeature('freemium') ? 'pt-24 pb-16' : 'py-16'">
        <slot />
      </div>
    </div>
    <layout-footer class="w-full" />
  </div>
</template>

<style lang="postcss">
@import "@/assets/css/main.css";
</style>
